import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img className="App-logo" src={logo} alt="logo" />
        <p>
          Comming soon... 
        </p>
      </header>
    </div>
  );
}

export default App;
